<script setup lang="ts">
import { useModal } from 'vue-final-modal'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void
}>()

const slots = useSlots()
const { open: open, close } = useModal({
  component: getAsyncComponent('SidebarOverlayModal'),
  attrs: {
    onClosed: () => {
      close()
      emit('update:visible', false)
    },
  },
  slots,
})

watch(
  () => props.visible,
  (value) => {
    if (value) {
      open()
    } else {
      close()
    }
  },
  { immediate: true },
)
</script>

<template>
  <div />
</template>
